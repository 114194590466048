import React from "react";
import { Navigation } from "../components/global/nav";
import { Footer } from "../components/global/footer";
import { graphql } from "gatsby";
import "../styles.css"
import SEO from "../components/global/seo";
import Breadcrumbs from "../components/global/breadcrumbs";
import CookiePrompt from "../components/global/cookies-prompt";

const Blog = props => {
   const blog_posts = props.data.allContentfulBlog.edges;
   //blog_posts.sort((a, b) => (new Date(a.node.createdAt)).localeCompare(new Date(b.node.createdAt)))

   return (
      <React.Fragment>
         <SEO
            title="Blog & Archives"
            slug="blog"
            metadescription="Visit tips, tricks and historical facts about now defunct companies with Smart Plan's blog & archive."
            image=""
            titleTemplate="%s - Smart Plan"
         />
         <CookiePrompt/>
         <Navigation/>
            <div className="standard-title-container default">
               <div className="standard-title-inner-container body-inner-width">
                  <div className="standard-title-inner-content">
                     <h1>Blog & Archive</h1>
                     <p>Get tips, tricks and historical facts about the past & present of boilers, central heating and appliances.</p>
                  </div>
               </div>
            </div>

            <div className="standard-container">
               <div className="standard-inner-container body-inner-width">
                  <div className="standard-content-container">
                     <div className="content-column">
                        <Breadcrumbs
                           location="blog"
                        />

                        <h2>Blog posts</h2>

                        {/* Create blog post stream */}
                        {blog_posts.map((post) => (
                           <a className="manufacturer-stream-card" href={`/${post.node.slug}`}>
                              <span>
                                 <h3>{post.node.title}</h3>
                                 <p>{post.node.metaDescription}</p>
                              </span>

                              <div className="manufacturer-stream-card-right">
                                 <img src={`https:${post.node.image.file.url}`}/>
                                 <a href={`/${post.node.slug}`} className="large-button green-button">Go to page</a>
                              </div>
                           </a>
                        ))}
                     </div>

                     {/* SIDEBAR */}
                     <div className="navigation-column">
                        <div className="navigation-entries-container">
                           <div class="sidebar-cta-container">
                              <h3>Speak to an engineer</h3>
                              <p>Call Smart Plan and speak to our expert engineering team free of charge.</p>
                              <a className="sidebar-cta-btn phone" href="tel:+443337726247">0333&nbsp;772&nbsp;6247</a>
                           </div>
                        </div>
                     </div> 
                  </div>
               </div>
            </div>
            <Footer/>
      </React.Fragment>
   )
}

export default Blog

export const pageQuery = graphql`
   query ContentfulBlogs {
      allContentfulBlog {
         edges {
            node {
               title,
               slug,
               image {
                  file {
                     url
                  }
               }
               createdAt
               metaDescription
            }
         }
      }
   }
`